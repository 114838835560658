import {
  joinTheGroup,
  addAGroup,
  saveSysUserRole,
  findGroupByClassId,
} from "./listApi";
import { ElMessage, ElNotification } from "element-plus";
import { ElMessageBox } from "element-plus";
import {
  addCompetitionGroup,
  addGroupUser,
  getCompetitionGroupList,
  joinCompetition,
} from "../apis";
import { userInfo } from "../methods";
import { fatherRoute } from "../datas";
// import {findGroupByUserIdAndClassId} from "../classApi";

export const getUserLists = (data) => {
  const reqData = {
    competitionId: data.parame.id,
  };
  getCompetitionGroupList(reqData)
    .then((res) => {
      const resData = res.data;
      if (resData.status === 0 || resData.code == 200) {
        data.spreadTable = capGroupTable(resData.data);
      } else {
        ElMessage.error(resData.msg);
      }
      data.loading = false;
    })
    .catch((e) => {
      data.loading = false;
    });
};
const capGroupTable = (groupTable) => {
  groupTable.forEach((group) => {
    group.columsInfo = `
          已有${group.currentGroupSize}人 /（${group.numberType} ${group.groupsNumber}人）
        `;
    group.columsStatus = group.isJoinGroup ? "可加入" : "已满员";
    group.singelSelect = false;
  });
  return groupTable;
};

// 切换条数
export const sizeChanges = (val, data) => {
  data.pageSize = val;
  getUserLists(data);
};

// 切换页数
export const currentChanges = (val, data) => {
  data.currentPage = val;
  getUserLists(data);
};

// 设置角色
export const dealRole = (val, data) => {
  getRoleList(data);
  data.showRoleDia = true;
  data.userItem = val;
  data.userValue = val.roleName;
};

// 确认修改
export const saveInfos = (data, router) => {
  if (!data.userValue) return ElMessage.warning("小组名称不能为空");
  const reqData = {
    groupName: data.userValue,
    competitionId: data.parame.id,
    userName: userInfo().userName,
    userId: userInfo().userId,
  };
  addCompetitionGroup(reqData)
    .then((res) => {
      const resData = res.data;
      if (resData.status === 0 || resData.code == 200) {
        ElMessage.success(resData.msg);
        backInfo(data);
        succeessInfo(0, router);
        // checkCards(router,data)
      } else {
        ElMessage.error(resData.msg);
      }
      data.loading = false;
    })
    .catch((e) => {
      data.loading = false;
    });
};
// 查找小组下的acid
export const checkCards = (router, data) => {
  let param = {
    userId: JSON.parse(sessionStorage.getItem("user")).userId,
    classId: data.classId,
    pageSize: 20,
    page: 1,
  };
  findGroupByUserIdAndClassId(param).then((res) => {
    if (res.data.code === "200") {
      let newRes = res.data.data;
      sessionStorage.setItem("classInfo", JSON.stringify(newRes.userGroup));
      sessionStorage.setItem("acid", newRes.userGroup.acid);
      sessionStorage.setItem("groupId", newRes.userGroup.groupId);
      sessionStorage.setItem("courseUserId", newRes.userGroup.courseUserId);
      // router.push('/course')
      router.push("/course/home");
    }
  });
};

// 取消修改
export const succeessInfo = (data, router) => {
  let tip = data === 0 ? "创建" : "加入";
  ElNotification({
    title: tip + "成功",
    message: "加入大赛成功",
    type: "success",
  });
  setTimeout(() => {
    if (data === 0) {
      // sessionStorage.setItem("mega", JSON.stringify(data.parame));
      // sessionStorage.setItem('acid', data.parame.acid)
    }
    router.go(-1);
  }, 300);
};
// 取消修改
export const backInfo = (data) => {
  data.userValue = "";
  data.showRoleDia = false;
};

// 查找所有角色
export const getRoleList = (data) => {
  let parame = {
    pageSize: 1000,
    pageIndex: 1,
  };
  sysRoles(parame).then((res) => {
    if (res.data.code === "200") {
      let newRes = res.data.data;
      data.userOptions = newRes.sysRolePageInfo.list;
    }
  });
};

export const clickButtons = (val, data, router) => {
  if (val.type === "加入") {
    ElMessageBox.confirm("确定是否加入该小组, 是否继续?", "提示", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning",
    }).then(() => {
      joinGroup(val.item, data, router);
    });
  }
};

export const joinGroup = (val, data, router) => {
  // let param = {
  //     username : JSON.parse(sessionStorage.getItem('user')).username,
  //     courseGroupUser:{
  //         userId: JSON.parse(sessionStorage.getItem('user')).userId,
  //         courseGroupId: val.courseGroupId
  //     }
  // }
  // joinTheGroup(param).then(res=>{
  //     if (res.data.code === '200'){
  //         let newRes = res.data.data
  //         succeessInfo(1,router)
  //         checkCards(router,data)
  //     }
  // })
  const reqData = {
    matchType: 1,
    // groupName: data.groupName,
    competitionId: val.competitionId,
    competitionGroupId: val.id,
    userName: userInfo().userName,
    userId: userInfo().userId,
  };
  addGroupUser(reqData)
    .then((res) => {
      const resData = res.data;
      if (resData.status === 0 || resData.code == 200) {
        sessionStorage.setItem("mega", JSON.stringify(val));
        sessionStorage.setItem("acid", val.acid);
        succeessInfo(1, router);
        // checkCards(router,data)
      } else {
        ElMessage.error(resData.msg);
      }
      data.loading = false;
    })
    .catch((e) => {
      data.loading = false;
    });
  // succeessInfo(1,router)
};
